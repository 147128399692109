import React, { useEffect, useRef, useState } from "react";
import styled, { keyframes, css } from "styled-components";

const CitiesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #e7e8e9;
  font-family: "Avenir_Book";
  overflow: hidden;
  border: 3px solid #ffa500;
  border-left: none;
  border-right: none;
  position: relative;
  z-index: 11;
  padding: 1rem 0;
`;

const CitiesTitle = styled.h3`
  font-size: 2rem;
  font-weight: 800;
  color: #ffa500;
  margin: 2rem 0;
`;

const CarouselWrapper = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
`;

const CityContainer = styled.div`
  display: flex;
  white-space: nowrap;
  margin-bottom: 2rem;
  ${({ $animation, $duration }) =>
    css`
      animation: ${$animation} ${$duration}s linear infinite;
    `};
`;

const Bullet = styled.p`
  font-size: 1.6rem;
  color: #101522;
  color: #ffa500;
  font-weight: 600;
  margin: 0 0.4rem;
  flex-shrink: 0;
`;

const City = styled.p`
  font-size: 1.4rem;
  color: #ffa500;
  color: #101522;
  font-weight: 600;
  flex-shrink: 0;
  margin: 0 1rem;
`;

const Cities = function ({ cities }) {
  const containerRef = useRef(null);
  const [totalWidth, setTotalWidth] = useState(0);

  useEffect(() => {
    if (containerRef.current) {
      const width = containerRef.current.scrollWidth / 2;
      setTotalWidth(width);
    }
  }, []);

  const extendedCities = [...cities, ...cities];
  const scrollDuration = 300;

  const carouselSlide = keyframes`
from { 
  transform: translateX(0)
}
to {
  /* transform: translateX(-40400px) */
  transform: translateX(-${totalWidth}px)
}
`;

  return (
    <CitiesWrapper>
      <CitiesTitle>Cities we've completed projects in...</CitiesTitle>
      <CarouselWrapper>
        <CityContainer
          ref={containerRef}
          $animation={carouselSlide}
          $duration={scrollDuration}
        >
          {extendedCities.map((city, i) => (
            <React.Fragment key={i}>
              <Bullet>&#8226;</Bullet>
              <City>{city}</City>
            </React.Fragment>
          ))}
        </CityContainer>
      </CarouselWrapper>
    </CitiesWrapper>
  );
};

export default Cities;
