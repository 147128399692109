import { Link } from "react-router-dom";
import styled from "styled-components";
import { FL, MA, RI, CT, CO, NC, SC } from "@state-icons/react";

const StateSectionWrapper = styled.section`
  box-sizing: border-box;
  font-family: "Avenir_Book";
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem 0;
  gap: 2rem;
  flex-wrap: wrap;
  border-top: 4px solid #ffa500;
  border-bottom: 4px solid #ffa500;
`;

const StateCard = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #585b64;
  text-decoration: none;
  border-radius: 12px;
  padding: 2rem;
  height: 6rem;
  width: 14rem;
  gap: 1rem;

  &:hover {
    box-shadow: inset 0 0 0 3px #ffa500;
  }
`;

const CardTitle = styled.h3`
  font-size: 2rem;
  font-weight: 600;
  color: #ffa500;
  text-align: center;
  margin: auto 0;
`;

const states = [
  "Florida",
  "Massachusetts",
  "Rhode Island",
  "Connecticut",
  "Colorado",
  "North Carolina",
  "South Carolina",
];

const postalCodes = [
  <FL size={50} color="#ffa500" />,
  <MA size={50} color="#ffa500" />,
  <RI size={50} color="#ffa500" />,
  <CT size={50} color="#ffa500" />,
  <CO size={50} color="#ffa500" />,
  <NC size={50} color="#ffa500" />,
  <SC size={50} color="#ffa500" />,
];

const ServiceAreaStates = function () {
  return (
    <StateSectionWrapper>
      {states.map((state, i) => {
        return (
          <StateCard to={`/service-areas/${state}`}>
            <CardTitle>{state}</CardTitle>
            {postalCodes[i]}
          </StateCard>
        );
      })}
    </StateSectionWrapper>
  );
};

export default ServiceAreaStates;
