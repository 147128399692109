import { keyframes } from "styled-components";

export const newFadeIn = keyframes`
from {
  opacity: 0;
}

to {
  opacity: 1;
}
`;

export const newFadeOut = keyframes`
from {
  opacity: 1
}
to {
  opacity: 0
  }`;

export const slideInFromRight = keyframes`
from {
  opacity: 0;
  transform: translateX(100px);
}

to {
  opacity: 1;
  transform: translateX(0);
}`;

export const slideInFromLeft = keyframes`
from {
  opacity: 0;
  transform: translateX(-100px);
}

to {
  opacity: 1;
  transform: translateX(0);
}`;

export const slideDown = keyframes`
from {
  transform: translateY(-100px);
  opacity: 0;
}

to {
  transform: translateY(0);
  opacity: 1;
}
`;

export const slideOutFromLeft = keyframes`
from {
  transform: (translateX(0));
  opacity: 1;
}
to {
transform: translateX(100px);
opacity: 0;
}
`;

export const CarouselSlideInRight = keyframes`
from {
  opacity: 0;
  transform: translateX(100%)
}

to {
  opacity: 1;
  transform: translateX(0)
}
`;

export const CarouselSlideInLeft = keyframes`
from {
  opacity: 0;
  transform: translateX(-100%)
}

to {
  opacity: 1;
  transform: translateX(0)
}
`;
