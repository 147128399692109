import styled from "styled-components";
import { newFadeIn, slideDown, slideInFromRight } from "../../animations.js";
import lunexPowerLogo from "../../images/LunexPowerLogoPlain.png";
import { FL } from "@state-icons/react";

const StateHeroWrapper = styled.section`
  font-family: "Avenir_Book";
  box-sizing: border-box;
  background-color: black;
  background-color: #585b64;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 80px);
`;

const StateTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin: 2rem;
  align-items: center;
  justify-content: center;
`;

const StateTitleLogo = styled.img`
  aspect-ratio: 3.6496;
  width: 34rem;
  margin-right: 1rem;
  animation: ${slideDown} 2s ease forwards;
`;

const StateIconWrapper = styled.div`
  animation: ${newFadeIn} 2s ease forwards;
`;

const StateHeroTitle = styled.h1`
  display: inline-block;
  font-size: 4rem;
  font-weight: 900;
  font-family: "Avenir_Book";
  color: white;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  margin-bottom: 0.8rem;
  opacity: 0;
  color: #ffa500;
  transform: translateX(100px);
  animation: ${slideInFromRight} 2s ease forwards;
`;

const StateHero = function ({ stateTitle, heroIcon }) {
  return (
    <StateHeroWrapper>
      <StateTitleWrapper>
        <StateTitleLogo src={lunexPowerLogo} alt="Lunex Power Logo" />
        <StateHeroTitle>{stateTitle}</StateHeroTitle>
      </StateTitleWrapper>
      <StateIconWrapper>{heroIcon}</StateIconWrapper>
    </StateHeroWrapper>
  );
};

export default StateHero;
