import styled from "styled-components";
import { newFadeIn, slideDown, slideInFromRight } from "../../animations.js";
import lunexPowerLogo from "../../images/LunexPowerLogoPlain.png";

const LocationsHeroWrapper = styled.section`
  font-family: "Avenir_Book";
  box-sizing: border-box;
  background-color: black;
  /* background: linear-gradient(to bottom right, #ffa500, #1897d9); */
  /* background: linear-gradient(to right, #ffa500, #1897d9); */
  background-color: #585b64;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 80px);
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 2rem;
  align-items: center;
  justify-content: center;
`;

const TitleLogo = styled.img`
  aspect-ratio: 3.6496;
  width: 34rem;
  margin-right: 1rem;
  animation: ${slideDown} 2s ease forwards;
`;

const LocationsHeroTitle = styled.h1`
  display: inline-block;
  font-size: 4rem;
  font-weight: 600;
  font-family: "Avenir_Book";
  color: white;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  margin-bottom: 0.8rem;
  opacity: 0;
  color: #ffa500;
  transform: translateX(100px);
  animation: ${slideInFromRight} 2s ease forwards;
`;

// const LocationCityWrapper = styled.div`
//   display: flex;
//   flex-wrap: wrap;
//   margin: 1rem;
//   gap: 1rem;
//   width: 90%;
//   justify-content: center;
//   animation: ${newFadeIn} 3s ease forwards;
// `;

// const LocationCity = styled.p`
//   font-size: 2rem;
//   color: white;
// `;

const Bullet = styled.p`
  font-size: 1.6rem;
  color: #ffa500;
  color: white;
  font-weight: 500;
`;

// const cities = [
//   "Florida",
//   "Massachusetts",
//   "Rhode Island",
//   "Connecticut",
//   "Colorado",
//   "North Carolina",
//   "South Carolina",
// ];

const LocationsHero = function () {
  return (
    <LocationsHeroWrapper>
      <TitleWrapper>
        <TitleLogo src={lunexPowerLogo} alt="Lunex Power Logo" />
        <LocationsHeroTitle>Service Areas</LocationsHeroTitle>
      </TitleWrapper>
      {/* <LocationCityWrapper>
        <Bullet>&#8226;</Bullet>
        {cities.map((city) => {
          return (
            <>
              <LocationCity>{city}</LocationCity>
              <Bullet>&#8226;</Bullet>
            </>
          );
        })}
      </LocationCityWrapper> */}
    </LocationsHeroWrapper>
  );
};

export default LocationsHero;
