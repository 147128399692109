import { Swiper, SwiperSlide } from "swiper/react";
import styled from "styled-components";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper";

const MobileStateWrapper = styled.section`
  box-sizing: border-box;
  font-family: "Avenir_Book";
  background-color: white;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
`;

const StateTitle = styled.h2`
  font-size: 3.8rem;
  color: #ffa500;
  font-weight: 600;
  margin: 1rem;
`;

const SwiperStyled = styled.div`
  max-width: 100%;
  .swiper-button-next,
  .swiper-button-prev {
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
  }

  .swiper-button-next::after,
  .swiper-button-prev::after {
    font-size: 18px;
  }
`;

const MobileLocationState2 = ({
  stateTitle,
  image1,
  image2,
  image3,
  image1Alt,
  image2Alt,
  image3Alt,
}) => {
  const slidesData = [
    [image1, image1Alt],
    [image2, image2Alt],
    [image3, image3Alt],
  ];

  return (
    <MobileStateWrapper>
      <StateTitle>{stateTitle}</StateTitle>
      <SwiperStyled>
        <Swiper
          slidesPerView={1}
          spaceBetween={0}
          loop={true}
          navigation={true}
          modules={[Navigation]}
          className="mySwiper"
          style={{
            width: "auto",
            height: "18.75rem",
            maxWidth: "21.875rem",
            borderRadius: "10px",
          }}
        >
          {slidesData.map((slide, index) => (
            <SwiperSlide key={slide[0] + index}>
              <img
                src={slide[0]}
                alt={slide[1]}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  borderRadius: "10px",
                }}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </SwiperStyled>
    </MobileStateWrapper>
  );
};

export default MobileLocationState2;
