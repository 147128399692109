import styled from "styled-components";

const SectionWrapper = styled.div`
  box-sizing: border-box;
  font-family: "Avenir_Book";
  background-color: white;
  display: flex;
  flex-direction: column;
  padding: 4rem 0;
  gap: 1rem;
  align-items: center;
  position: relative;
  z-index: 11;
  border-bottom: 4px solid #ffa500;
`;

const ContactTitle = styled.h3`
  font-size: 2rem;
  font-weight: 800;
  color: #ffa500;
`;

const LineWrapper = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`;

const ContactText = styled.p`
  font-size: 1.4rem;
  font-weight: 600;
  color: #101522;
`;

const ButtonLink = styled.a`
  font-family: "Avenir_Book";
  font-size: 1rem;
  font-weight: 600;
  padding: 0.8rem 1.4rem;
  background-color: #1ba8f1;
  background-color: #ffa500;
  color: black;
  transition: all 0.3s;
  border-radius: 50px;
  text-decoration: none;
  color: white;

  &:hover {
    background-color: #ffa500;
    background-color: #1ba8f1;
    cursor: pointer;
  }
`;

const ContactFormButton = function ({ stateTitle }) {
  return (
    <SectionWrapper>
      <ContactTitle>Contact us</ContactTitle>
      <LineWrapper>
        <ContactText>
          Contact Lunex {stateTitle ? stateTitle : "Power"}
        </ContactText>
        <ButtonLink>CONTACT US</ButtonLink>
      </LineWrapper>
      <LineWrapper>
        <ContactText>Self service pricing on Lunex Direct app</ContactText>
        <ButtonLink href="https://app.lunexdirect.solar">GO TO APP</ButtonLink>
      </LineWrapper>
    </SectionWrapper>
  );
};

export default ContactFormButton;
