import styled, { css } from "styled-components";
import {
  newFadeIn,
  newFadeOut,
  slideInFromRight,
  slideOutFromLeft,
} from "../../animations";
import { useEffect, useRef, useState } from "react";

export const StateWrapper = styled.div`
  box-sizing: border-box;
  font-family: "Avenir_Book";
  background-color: white;
  position: relative;
  display: flex;
  margin: 0 auto;
  max-width: 100rem;
`;

export const OuterWrapper = styled.div`
  position: relative;
  overflow: hidden;
  height: 100vh;
  width: 100%;
`;

export const StickyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 80px;
  margin: 0 1rem;
  background: white;
  z-index: 10;
  align-items: center;
  max-height: 40rem;
  width: 100%;

  @media (min-width: 65.625em) and (max-height: 56.25em) {
    //1050px x 900px
    max-height: 42rem;
    top: calc(80px + 2rem);
  }

  @media (min-width: 65.625em) and (min-height: 56.25em) {
    //1050px x 900px
    top: calc(80px + 5rem);
    max-height: 45rem;
  }

  @media (min-width: 65.625em) and (max-height: 37.5em) {
    max-height: 34rem;
  }
`;

export const PictureTextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
`;

export const StateTitle = styled.h2`
  color: #ffa500;
  font-size: 3.2rem;
  font-weight: 600;
  text-align: center;
  margin: 1.5rem 0;

  ${({ $visible }) =>
    $visible
      ? css`
          animation: ${newFadeIn} 1.5s ease forwards;
        `
      : !$visible
      ? css`
          animation: ${newFadeOut} 1.5s ease forwards;
        `
      : ""};

  @media (max-height: 37.5em) {
    // 600px
    font-size: 2.8rem;
  }
`;

export const ScrollingWrapper = styled.div`
  height: 200vh;
  display: flex;
  position: relative;
  z-index: 9;
  width: 100%;
`;

export const StateImage = styled.img`
  max-width: 40%;
  border-radius: 10px;
  transition: opacity 1.5s ease;
  animation: ${({ $activeImage, $image1, $image2, $image3 }) =>
      $activeImage === $image1
        ? css`
            ${newFadeIn}
          `
        : $activeImage === $image2
        ? css`
            ${newFadeIn}
          `
        : $activeImage === $image3
        ? css`
            ${newFadeIn}
          `
        : $activeImage === null
        ? css`
            ${newFadeOut}
          `
        : css`
            ${newFadeIn}
          `}
    1s ease-in-out forwards;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

export const TitleParagraphWrapper = styled.div`
  max-width: 58vw;
  margin: 0 auto 1rem 1rem;
  opacity: ${({ $visible }) => ($visible ? 1 : 0)};
  animation: ${({ $slideIn }) =>
    $slideIn
      ? css`
          ${slideInFromRight} 1s ease forwards
        `
      : !$slideIn
      ? css`
          ${slideOutFromLeft} 1s ease forwards
        `
      : "none"};
`;

export const CityTitle = styled.h3`
  font-size: 1.2rem;
  color: #ffa500;
  font-weight: 600;
  @media (max-height: 37.5em) {
    // 600px
    font-size: 0.9rem;
  }
`;

export const CityParagraph = styled.p`
  font-size: 1.1rem;

  @media (max-height: 37.5em) {
    // 600px
    font-size: 0.9rem;
  }
`;

const LocationState = function ({
  stateTitle,
  image1,
  image1Alt,
  image2,
  image2Alt,
  image3,
  image3Alt,
  cityTitle1,
  cityTitle2,
  paragraph1,
  paragraph2,
  paragraph3,
}) {
  const [activeImage, setActiveImage] = useState(null);
  const [titleVisible, setTitleVisible] = useState(false);
  const [activeSelection, setActiveSelection] = useState({
    firstParagraph: false,
    secondParagraph: false,
    thirdParagraph: false,
  });

  const scrollingRef = useRef();
  const titleRef = useRef();

  useEffect(() => {
    const handleScroll = () => {
      const scrollRef = scrollingRef.current;

      if (scrollRef.getBoundingClientRect().top <= window.innerHeight / 2) {
        setActiveImage(image1);
        setActiveSelection((prev) => ({ ...prev, firstParagraph: true }));
      } else if (
        scrollRef.getBoundingClientRect().top >
        window.innerHeight / 2
      ) {
        setActiveSelection((prev) => ({ ...prev, firstParagraph: false }));
        setActiveImage(null);
      }

      if (scrollRef.getBoundingClientRect().top <= 0) {
        setActiveImage(image2);
        setActiveSelection((prev) => ({ ...prev, secondParagraph: true }));
      } else if (scrollRef.getBoundingClientRect().top > 0) {
        setActiveSelection((prev) => ({ ...prev, secondParagraph: false }));
      }

      if (scrollRef.getBoundingClientRect().top < -(0.5 * window.innerHeight)) {
        setActiveImage(image3);
        setActiveSelection((prev) => ({ ...prev, thirdParagraph: true }));
      } else if (
        scrollRef.getBoundingClientRect().top > -(0.5 * window.innerHeight)
      ) {
        setActiveSelection((prev) => ({ ...prev, thirdParagraph: false }));
      }
    };

    const handleTitleAnimation = () => {
      const tRef = titleRef.current;

      if (tRef.getBoundingClientRect().top <= window.innerHeight / 1.5) {
        setTitleVisible(true);
      } else if (tRef.getBoundingClientRect().top > window.innerHeight / 1.5) {
        setTitleVisible(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    window.addEventListener("scroll", handleTitleAnimation);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("scroll", handleTitleAnimation);
    };
  }, [image1, image2, image3]);

  console.log(`width: ${window.innerWidth}, height: ${window.innerHeight}`);

  return (
    <StateWrapper>
      <ScrollingWrapper ref={scrollingRef}>
        <StickyWrapper>
          <StateTitle $visible={titleVisible} ref={titleRef}>
            {stateTitle}
          </StateTitle>

          <PictureTextWrapper>
            {activeImage === image1 ? (
              <StateImage
                src={image1}
                alt={image1Alt}
                $activeImage={activeImage}
                key="image1"
                $image1={image1}
                $image2={image2}
                $image3={image3}
              />
            ) : activeImage === image2 ? (
              <StateImage
                src={image2}
                alt={image2Alt}
                key="image2"
                $activeImage={activeImage}
                $image1={image1}
                $image2={image2}
                $image3={image3}
              />
            ) : activeImage === image3 ? (
              <StateImage
                src={image3}
                alt={image3Alt}
                key="image3"
                $activeImage={activeImage}
                $image1={image1}
                $image2={image2}
                $image3={image3}
              />
            ) : activeImage === null ? (
              <StateImage
                src={image1}
                alt="City Image"
                $activeImage={activeImage}
                key={image1}
                $image1={image1}
                $image2={image2}
                $image3={image3}
              />
            ) : (
              ""
            )}

            <ContentWrapper>
              <TitleParagraphWrapper
                $slideIn={activeSelection.firstParagraph}
                $visible={activeSelection.firstParagraph}
                id="florida"
                $section={"florida"}
              >
                <CityParagraph>{paragraph1}</CityParagraph>
              </TitleParagraphWrapper>

              <TitleParagraphWrapper
                id="tampa"
                $section={"tampa"}
                $slideIn={activeSelection.secondParagraph}
                $visible={activeSelection.secondParagraph}
              >
                <CityTitle>{cityTitle1}</CityTitle>
                <CityParagraph>{paragraph2}</CityParagraph>
              </TitleParagraphWrapper>

              <TitleParagraphWrapper
                id="orlando"
                $section={"orlando"}
                $slideIn={activeSelection.thirdParagraph}
                $visible={activeSelection.thirdParagraph}
              >
                <CityTitle>{cityTitle2}</CityTitle>
                <CityParagraph>{paragraph3}</CityParagraph>
              </TitleParagraphWrapper>
            </ContentWrapper>
          </PictureTextWrapper>
        </StickyWrapper>
      </ScrollingWrapper>
    </StateWrapper>
  );
};

export default LocationState;
